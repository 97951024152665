import { Injectable } from '@angular/core';
import { PatchedLocation, Location } from 'src/api/models';
import { Country } from 'src/api/models/country';
import { Currency } from 'src/api/models/Currency';
import { LocationsService } from 'src/api/services';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsFacadeService {

  constructor(
    public locationService: LocationsService,
    public authService: AuthService,
  ) { }

  async getLocations(): Promise<Location[]> {
    return await (await this.locationService.locationsList()).toPromise() as Location[];
  }

  async getCurrency(): Promise<Currency[]> {
    return await (await this.locationService.currencyList()).toPromise() as unknown as any[];
  }

  async getSupportDetails(): Promise<Currency[]> {
    return await (await this.locationService.SupportDetail()).toPromise() as unknown as any[];
  }

  async getLocationsCountry(): Promise<Country[]> {
    return await (await this.locationService.locationCountryList()).toPromise() as Country[];
  }

  async getLocation(id: number): Promise<Location> {
    return await (await this.locationService.locationsRetrieve({
      id
    })).toPromise();
  }

  async addLocation(location: Location): Promise<Location> {
    const company = await (await this.authService.getUserInfo()).company;
    location.company = company;
    return await (await this.locationService.locationsCreate$Json({
      body: location
    })).toPromise();
  }

  async updateLocation(location: PatchedLocation): Promise<Location> {
    return await (await this.locationService.locationsPartialUpdate$Json({
      id: location.id,
      body: location
    })).toPromise();
  }

  async deleteLocation(location: Location): Promise<any> {
    return await (await this.locationService.locationsDestroy({
      id: location.id
    })).toPromise();
  }
}
