/* tslint:disable */
/* eslint-disable */
export enum RolesEnum {
  Customer = 'CUSTOMER',
  LocManager = 'LOC_MANAGER',
  MacManager = 'MAC_MANAGER',
  SpEmployee = 'SP_EMPLOYEE',
  SpEmpManager = 'SP_EMP_MANAGER',
  TimeRequired = 'TIME_MANAGER'
}
