import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OrderStateStep, ServiceProviderOrderSteps } from './order-status-stepper.config';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { EmployeeOrder, Order, RolesEnum, StatusEnum, Type2DeEnum } from 'src/api/models';
import { OrderStatusStepperService } from './order-status-stepper.facade.service';
import { loader } from 'src/app/shared/decorators/loader';
import { completionNotifier } from 'src/app/shared/decorators/completion-notifier';
import { FurtherdatevalidationPage } from 'src/app/pages/timetracker/furtherdatevalidation/furtherdatevalidation.page';
import { ModalController, PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { SignatureComponent } from '../../signature/signature.component';

@Component({
  selector: 'app-order-status-stepper',
  templateUrl: './order-status-stepper.component.html',
  styleUrls: ['./order-status-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class OrderStatusStepperComponent implements AfterViewInit {

  @Input() order: Order | EmployeeOrder;
  @Output() orderStateChange = new EventEmitter<StatusEnum>();
  @Input() orderSteps: OrderStateStep[];
  @Output() offerCreateClick = new EventEmitter<StatusEnum>();
  @Input() userType = "";


  role: RolesEnum;
  isReject = false;

  formGroup = new FormGroup({
    cancelled_at: new FormControl(null, Validators.required),
    accepted_at: new FormControl(null, Validators.required),
    on_the_way_at: new FormControl(null, Validators.required),
    work_started_at: new FormControl(null, Validators.required),
    work_completed_at: new FormControl(null, Validators.required),
    completed_at: new FormControl(null, Validators.required)
  });
  @ViewChild('stepper', { static: true }) private stepper: MatStepper;
  readonly StatusEnum = StatusEnum;

  get customer() {
    if (this.role) {
      return this.role === RolesEnum.Customer;
    }

    return false;
  }

  get serviceProvider() {
    if (this.role) {
      return this.role === RolesEnum.SpEmployee;
    }

    return false;
  }

  constructor(
    public changeDetector: ChangeDetectorRef,
    public orderStatusStepperService: OrderStatusStepperService,
    public popoverController: PopoverController,
    public modalController: ModalController,

  ) { }

  async ngAfterViewInit() {
    this.role = await this.orderStatusStepperService.getRole();
    if (this.order.status == "REJECTED") {
      this.isReject = true;
      if (this.role != RolesEnum.Customer) {
        this.order.status = StatusEnum.WorkStarted
        this.order.work_completed_at = null
      }
    }
    this.updateForm();
    this.updateStepper();
    //console.log("role", this.role);
    //console.log("user type = ", this.userType);

    if (this.role != RolesEnum.Customer && this.order.type == Type2DeEnum.NewProject) {
      // this.stepper.next();
    }


  }

  @completionNotifier('', false)
  async performAction(state) {
    //console.log("asdf perform  =  ");

    await this.orderStatusStepperService.setOrderStatus(this.order, state);
    await this.fetchOrder();
    await this.updateForm();
    await this.updateStepper();
    // this.stepper.next();

    if (this.order.status == "REQUESTED_TO_CONFIRM") {
      this.order.work_completed_at = moment().format("YYYY-MM-DDTHH:mm:ss+0000")
      this.stepper.next();
    }
  }

  @loader()
  async fetchOrder() {
    this.order = await this.orderStatusStepperService.retrieveOrder(this.order);
    //console.log("orrrr", this.order);

    this.orderStateChange.emit(this.order.status);
  }

  async updateForm() {
    await this.formGroup.patchValue({
      ...this.order
    });
  }

  createOffer() {
    this.offerCreateClick.emit()
  }

  async updateStepper() {
    //console.log("this.order.", this.order);

    var lastDoneState = 0;
    this.stepper.steps.forEach(async (step, i) => {
      //console.log("valid", step.stepControl.status);

      if (step.stepControl.status === 'VALID') {

        step.state = 'done';
        if (this.serviceProvider) {
          if (this.order.status == "REQUESTED_TO_CONFIRM") {
            lastDoneState = i + 1;
            this.order.work_completed_at = moment().format("YYYY-MM-DDTHH:mm:ss+0000")
            this.stepper.next();
            //console.log("last done step ", lastDoneState);
          }
          else {
            lastDoneState = i + 1
          }
        }
        else if ((this.order.work_completed_at + "") == "null" && (this.order.work_started_at + "") != "null" && this.customer) {
          lastDoneState = i + 1;
        }
        else {
          lastDoneState = i;
        }



      }
    });

    //console.log("Last Indx", lastDoneState);

    this.stepper.selectedIndex = lastDoneState;

    this.changeDetector.detectChanges();
  }

  async confirmOrder(item) {
    //console.log("asdf =  ");

    const popover = await this.popoverController.create({
      component: FurtherdatevalidationPage,
      cssClass: 'confirm-search-candidates-popover',
      translucent: false,
      componentProps: {
        msg: "I hereby accept the work bindingly",
        fromOrder: "2",
        title: "Work accept"
      },
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();

    // //console.log(data);

    if ((data["approved"] + "") == "true") {

      const modal = await this.modalController.create({
        component: SignatureComponent,
        componentProps: {
          fromCustomer: "1"
        },
      });

      (modal).present();

      const result = await modal.onDidDismiss();
      if (result.data) {
        if (this.order.status != StatusEnum.WorkComplete) {
          await this.confirmOrderApiCall('WORK_COMPLETE')
        }
        await this.confirmOrderApiCall('COMPLETE')
      }


    }

  }

  async problemOrder(item) {
    if (this.order.status != StatusEnum.Rejected) {
      const popover = await this.popoverController.create({
        component: FurtherdatevalidationPage,
        cssClass: 'confirm-search-candidates-popover',
        translucent: false,
        componentProps: {
          msg: "I hereby partially reject the work performed. I will immediately inform the craftsman of the points of complaint in the order.",
          fromOrder: "2",
          title: "Work not accept"
        },
      });
      await popover.present();

      const { data } = await popover.onDidDismiss();

      // //console.log(data);

      if ((data["approved"] + "") == "true") {
        this.confirmOrderApiCall('REJECTED')
      }

    }

  }

  @loader()
  async confirmOrderApiCall(state: any) {
    var res = await this.orderStatusStepperService.confirmOrder((this.order.id + ""), state);
    //console.log(res);
    // await this.fetchOrder();
    if (state == "REJECTED") {
      this.order.status = StatusEnum.Rejected;

    }
    else if (state == "COMPLETE") {
      this.order.work_completed_at = moment().format("YYYY-MM-DDTHH:mm:ss+0000")
      this.order.completed_at = moment().format("YYYY-MM-DDTHH:mm:ss+0000")
      this.order.status = StatusEnum.Complete;
      await this.updateForm();
      await this.updateStepper();
      // this.stepper.next();
    }
    else {
      this.order.work_completed_at = moment().format("YYYY-MM-DDTHH:mm:ss+0000")
      this.order.status = StatusEnum.WorkComplete;

      await this.updateForm();
      await this.updateStepper();
      // this.stepper.next();
    }


  }

}
