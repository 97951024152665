import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { RolesEnum } from 'src/api/models';
import { appPages } from 'src/app/shared/components/menu/side-menu/side-menu.component.config';
import { completionNotifier } from 'src/app/shared/decorators/completion-notifier';
import { loader } from 'src/app/shared/decorators/loader';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {

  public appPages = appPages;
  loginSubscription: Subscription;
  loggedIn = false;
  isCompany = false;

  constructor(
    public authService: AuthService,
    public permissionsService: NgxPermissionsService,
  ) { }


  ngOnInit() {

    this.checkIsComp();

  }

  ionViewWillEnter() {

  }

  @loader()
  async checkIsComp() {
    // var userInfo = await this.authService.getUserInfo();


    // this.isCompany = userInfo["is_company"]
    // //console.log("uuu", this.isCompany);
    // if (userInfo["is_company"] == false) {
    //   this.appPages[6].requiredRoles = [RolesEnum.TimeRequired]
    // }
    // this.appPages = this.appPages

    this.loginSubscription = this.authService.isAuthenticated$.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
    });
    // window.location.reload();

  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }

}
