import { StatusEnum as OrderState } from 'src/api/models';
import { Label } from 'src/app/shared/label';

export interface OrderStateStep {
    label?: string;
    text?: string;
    nextButtonText?: string;
    orderState: OrderState;
    checked?: boolean;
    expanded?: boolean;
    formField: string;
}

export const ServiceProviderOrderSteps: OrderStateStep[] = [
    {
        orderState: OrderState.Accepted,
        label: 'accept-order',
        formField: 'accepted_at',
    },
    {
        orderState: OrderState.OnTheWay,
        label: 'on-the-way',
        formField: 'on_the_way_at'
    },
    {
        orderState: OrderState.WorkStarted,
        label: 'work-started-4c277e0333b895eebfb0651bc2be00cc',
        formField: 'work_started_at'
    },
    {
        orderState: OrderState.WorkComplete,
        label: 'work-complete',
        formField: 'work_completed_at'
    },
    {
        orderState: OrderState.Complete,
        label: 'take-signature',
        formField: 'completed_at',
        text: 'have-the-client-confirm-the-completion-of-your-wor'
    }
];

export const CustomerOrderSteps: OrderStateStep[] = [
    {
        orderState: OrderState.Accepted,
        label: 'order-accepted',
        formField: 'accepted_at'
    },
    {
        orderState: OrderState.OnTheWay,
        label: 'technician-on-the-way',
        formField: 'on_the_way_at'
    },
    {
        orderState: OrderState.WorkStarted,
        label: 'work-started-4c277e0333b895eebfb0651bc2be00cc',
        formField: 'work_started_at'
    },
    {
        orderState: OrderState.WorkComplete,
        label: 'work-complete',
        formField: 'work_completed_at'
    },
    {
        orderState: OrderState.Complete,
        label: 'order-completed',
        formField: 'completed_at'
    }
];


export const CUSTOMER_ORDER_STATUS_LABELS: Label[] = [
    {
        key: OrderState.Draft,
        value: 'Draft'
    },
    {
        key: OrderState.Finalized,
        value: 'in-queue',
    },
    {
        key: OrderState.Received,
        value: 'Offer Received',
    },
    {
        key: OrderState.Accepted,
        value: 'order-accepted',
    },
    {
        key: OrderState.OnTheWay,
        value: 'on-the-way',
    },
    {
        key: OrderState.Rejected,
        value: 'Rejected',
    },
    {
        key: OrderState.WorkStarted,
        value: 'work-started-4c277e0333b895eebfb0651bc2be00cc',
    },
    {
        key: OrderState.WorkComplete,
        value: 'work-complete',
    },
    {
        key: OrderState.RequestWorkComplete,
        value: 'confirm work complete',
    },
    {
        key: OrderState.Complete,
        value: 'order-completed',
    }
];
