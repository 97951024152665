/* tslint:disable */
/* eslint-disable */
export enum AreaEnum {
  General = 'GENERAL',
  Door = 'DOOR',
  Plants = 'PLANTS',
  Outdoor = 'OUTDOOR',
  CarTruck = 'CAR_TRUCK',
  Refrigeration = 'REFRIGERATION',
  ForkliftsConveyors = 'FORKLIFTS_CONVEYORS',
  BuildingServices = 'BUILDING_SERVICES',
  LiftingPlatforms = 'LIFTING_PLATFORMS',
  WindowGlass = 'WINDOW_GLASS',
  DrywallMasonry = 'DRYWALL_MASONRY',
  EventTech = 'EVENT_TECH',
  ItPresentationTech = 'IT_PRESENTATION_TECH',
  Welding = 'WELDING',
  Cleaning = 'CLEANING',
  EMobility = 'E_MOBILITY',
  POOL_POND = "POOL_POND",
  MOVING_LOGISTICS = "MOVING_LOGISTICS",
  TILES_FLOOR = "TILES_FLOOR",
  PAINTING_WALLPAPERING = "PAINTING_WALLPAPERING",
  CONSTRUCTION = "CONSTRUCTION",
  ROOF = "ROOF",
  WOODWORK_CARPENTER = "WOODWORK_CARPENTER",
  INTERIOR_DESIGN = "INTERIOR_DESIGN"
}
