import { Component, Input, OnInit } from '@angular/core';
import { Distance } from 'src/app/shared/distance';
import { Storage } from '@capacitor/storage';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { ServiceProviderUser } from 'src/api/models';
import { AccountService } from 'src/api/services';

@Component({
  selector: 'app-search-result-item',
  templateUrl: './search-result-item.component.html',
  styleUrls: ['./search-result-item.component.scss'],
})
export class SearchResultItemComponent implements OnInit {

  @Input() serviceProvider: any;
  Currency = "";
  readonly Distance = Distance;
  @Input() detail = false;
  imgsDocument = []

  get distance() {
    if (this.serviceProvider && (this.serviceProvider.distance != null)) {
      return this.serviceProvider.distance.toFixed(1).toString();
    }

    return null;
  }

  constructor(public viewer: PhotoViewer, private accountService: AccountService) { }

  ngOnInit() {
    this.getCurrency();
    //console.log(this.serviceProvider);


  }

  getFileExtension(filename) {

    // get file extension
    const extension = filename.split('.').pop();
    return extension;
  }

  async getCurrency() {
    let curCode = await Storage.get({ key: "curSymbol" });
    this.Currency = curCode.value;


    var resul = await this.getUserById(this.serviceProvider.id) as any;

    for (var i = 0; i < resul.length; i++) {
      var fileExt = this.getFileExtension(resul[i]["document"]);
      //console.log("ext=", fileExt);

      if ((fileExt + "").toLowerCase() == "png" || (fileExt + "").toLowerCase() == "jpg" || (fileExt + "").toLowerCase() == "jpeg") {
        resul[i]["type"] = "IMAGE"
      }
      else {
        resul[i]["type"] = "DOCUMENT"
      }

    }

    //console.log("cr ciode", resul);
    this.imgsDocument = resul

  }

  async getUserById(userId: string) {
    return await (await this.accountService.accountUserDocumentRetrieve({
      id: userId
    })).toPromise() as unknown as any;
  }

  viewphoto(url: string) {
    //console.log(url);
    var photoUrl = url;
    var title = "Photo";
    var options = {
      share: true
    }
    this.viewer.show(photoUrl, title, options);
  }

}
