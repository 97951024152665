/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { CompanyFacadeService } from 'src/app/pages/company/company-facade.service';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfiguration {
  rootUrl: string = '';
}

export class StripeConfig2 {
  secretKey: string = '';
  publicSecret: string = '';
  companyFacadeService: CompanyFacadeService

}

export const StripeConfig = new StripeConfig2();

/**
 * Parameters for `ApiModule.forRoot()`
 */
export interface ApiConfigurationParams {
  rootUrl?: string;
}
