import { Injectable } from '@angular/core';
import { PatchedUser, ServiceProviderProfile, ServiceProviderUser, User, UserProfile } from 'src/api/models';
import { AccountService, CompaniesService } from 'src/api/services';
import { LocationsFacadeService } from '../../locations/locations-facade.service';
import * as _ from 'lodash';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { Storage } from '@capacitor/storage';


@Injectable({
  providedIn: 'root'
})
export class ProfileFacadeService {

  constructor(
    private companyService: CompaniesService,
    private accountService: AccountService,
    private locationService: LocationsFacadeService,
    private http: HttpClient
  ) { }

  async getCompanies() {
    return await (await this.companyService.companiesList()).toPromise();
  }

  async getLocations() {
    return await this.locationService.getLocations();
  }

  async getCurrency() {
    return await this.locationService.getCurrency();
  }

  async getSupportDetail() {
    return await this.locationService.getSupportDetails();
  }

  async getLocationsCountry() {
    return await this.locationService.getLocationsCountry();
  }

  async getUserInfo() {
    return await (await this.accountService.accountUserMeRetrieve()).toPromise().then((user: any) => {

      if (user.options) {
        if (user.options.experience) {
          user.options.experience = _.sortBy(user.options.experience, 'name');
        }

        if (user.options.certificates) {
          user.options.certificates = _.sortBy(user.options.certificates, 'name');
        }

        if (user.options.training) {
          user.options.training = _.sortBy(user.options.training, 'name');
        }
      }
      return user;
    });
  }

  async updateUserInfo(user: PatchedUser) {
    return await (await this.accountService.accountUserPartialUpdate$FormData({
      id: user.id,
      body: user
    })).toPromise();
  }

  async updateStripeUserInfo(id: any) {
    return await (await this.accountService.accountStripeUserPartialUpdate$FormData({
      stripe_customer_id: id,

    })).toPromise();
  }

  async updateServiceProviderProfile(user: ServiceProviderUser | any) {
    return await (await this.accountService.accountUserPartialUpdate$Json({
      id: user.id,
      body: user
    })).toPromise();
  }

  async getUserById(userId: string) {
    return await (await this.accountService.accountUserRetrieve({
      id: userId
    })).toPromise() as unknown as ServiceProviderUser;
  }

  private formDataValue(value: any): any {
    if (value === null || value === undefined) {
      return null;
    }
    if (value instanceof Blob) {
      return value;
    }
    if (typeof value === 'object') {
      return JSON.stringify(value);
    }
    return String(value);
  }

  async uploadProfilePicture(user: ServiceProviderUser, picture: File) {
    return await (await this.accountService.accountUserProfilePictureUpdate({
      id: user.id,
      body: {
        profile_picture: picture
      } as any
    })).toPromise();
    // var dt = await Storage.get({ key: "token" });

    // var token = dt.value;
    // //console.log("profile token",token);
    // var formData = new FormData();
    // let httpOptions = {
    //     headers: new HttpHeaders({
    //         'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    //         'Authorization' : 'Token ' + token,
    //         'Accept': 'application/json'
    //     })
    // };


    // formData.append('profile_picture',this.formDataValue(picture));

    // // rest data to the form.
    // //Object.keys(restObj).forEach(key => {
    // //  formData.append(key, restObj[key]);
    // //});
    // //console.log("prof form data",formData.getAll("profile_picture"));
    // // Send it.
    // return this.http.put("https://django.eservsol.com:8082/api/v1/account/user/"+user.id+"/profile_picture/", formData,httpOptions)
    //   .toPromise()
    //   .catch((e) => {
    //       //console.log(e);
    //     // handle me
    //   });

  }

  async uploadEmpProfilePicture(user: ServiceProviderUser, picture: File, loginUserId: string) {
    return await (await this.accountService.accountUserEmpProfilePictureUpdate({
      id: loginUserId,
      body: {
        profile_picture: picture,
        userId: user.id
      } as any
    })).toPromise();
  }

  async deleteProfiledocumentPicture(user: any) {
    return await (await this.accountService.deleteProfileDocumentUpdate({
      id: user.id,
      body: {

      } as any
    })).toPromise();


  }

  async uploadCompanyProfilePicture(user: any, picture: File) {
    return await (await this.accountService.accountCompanyProfilePictureUpdate({
      id: user.id,
      body: {
        profile_picture: picture
      } as any
    })).toPromise();

  }

  async uploadSpDocument(user: ServiceProviderUser, picture: File) {
    return await (await this.accountService.accountUserSpDocumentUpdate({
      id: user.id,
      body: {
        document: picture
      } as any
    })).toPromise();
    // var dt = await Storage.get({ key: "token" });

    // var token = dt.value;
    // //console.log("profile token",token);
    // var formData = new FormData();
    // let httpOptions = {
    //     headers: new HttpHeaders({
    //         'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
    //         'Authorization' : 'Token ' + token,
    //         'Accept': 'application/json'
    //     })
    // };


    // formData.append('profile_picture',this.formDataValue(picture));

    // // rest data to the form.
    // //Object.keys(restObj).forEach(key => {
    // //  formData.append(key, restObj[key]);
    // //});
    // //console.log("prof form data",formData.getAll("profile_picture"));
    // // Send it.
    // return this.http.put("https://django.eservsol.com:8082/api/v1/account/user/"+user.id+"/profile_picture/", formData,httpOptions)
    //   .toPromise()
    //   .catch((e) => {
    //       //console.log(e);
    //     // handle me
    //   });

  }
}
