import { RolesEnum } from 'src/api/models/roles-enum';



export var appPages = [
    {
        title: 'Login',
        url: '/auth/login',
        icon: 'log-in',
        menuIndex: 1,
        shownBeforeLogin: true,
        shownAfterLogin: false,
        requiredRoles: []
    },
    {
        title: 'Registration',
        url: '/auth',
        icon: 'person-add',
        menuIndex: 2,
        shownBeforeLogin: true,
        shownAfterLogin: false,
        requiredRoles: []
    },
    {
        title: 'customer-dashboard',
        url: '/customers/dashboard',
        icon: 'bar-chart',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.Customer]
    },
    {
        title: 'service-provider-dashboard',
        url: '/',
        icon: 'bar-chart',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmployee]
    },
    {
        title: 'Profile',
        url: '/auth/profile/customer',
        icon: 'person',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.Customer]
    },
    {
        title: 'Profile',
        url: '/auth/profile/service-provider',
        icon: 'person',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmployee]
    },
    {
        title: 'time-management',
        url: '/timetracker/',
        icon: 'time',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.TimeRequired]
    },
    {
        title: 'location-management',
        url: '/locations',
        icon: 'storefront',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.LocManager]
    },
    {
        title: 'employee-management',
        url: '/technicians/manager',
        icon: 'people',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmpManager]
    },
    {
        title: 'new-order',
        url: '/customers/orders/new',
        icon: 'add',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.Customer]
    },
    {
        title: 'order-overview',
        url: '/customers/orders',
        icon: 'document',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.Customer]
    },
    {
        title: 'order-overview',
        url: '/technicians/orders',
        icon: 'document',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmployee]
    },
    {
        title: 'Item list',
        url: '/technicians/itemactivity',
        icon: 'list',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmployee]
    },
    {
        title: 'company-profile',
        url: '/company/company-detail',
        icon: 'business',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: []
    },
    // {
    //     title: 'Payment methods',
    //     url: '/technicians/paymentmethod',
    //     icon: 'card',
    //     menuIndex: 2,
    //     shownBeforeLogin: false,
    //     shownAfterLogin: true,
    //     requiredRoles: [RolesEnum.Customer]
    // },
    {
        title: 'Bank Details',
        url: '/technicians/bankdetail',
        icon: 'card',
        menuIndex: 2,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: [RolesEnum.SpEmployee]
    },
    {
        title: 'Settings',
        url: '/settings',
        icon: 'settings',
        menuIndex: 2,
        shownBeforeLogin: true,
        shownAfterLogin: true,
        requiredRoles: []
    },
    {
        title: 'Logout',
        url: '/auth/logout',
        icon: 'log-out',
        menuIndex: 3,
        shownBeforeLogin: false,
        shownAfterLogin: true,
        requiredRoles: []
    },
];
