import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-furtherdatevalidation',
  templateUrl: './furtherdatevalidation.page.html',
  styleUrls: ['./furtherdatevalidation.page.scss'],
})
export class FurtherdatevalidationPage implements OnInit {

  msg = "";
  fromOrder = ""
  title = ""

  constructor(public popoverController: PopoverController) { }

  ngOnInit() {
  }

}
