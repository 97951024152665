/* tslint:disable */
/* eslint-disable */
export enum StatusEnum {
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
  Received = "RECEIVED_OFFER",
  Accepted = 'ACCEPTED',
  OnTheWay = 'ON_THE_WAY',
  WorkStarted = 'WORK_STARTED',
  WorkComplete = 'WORK_COMPLETE',
  RequestWorkComplete = 'REQUESTED_TO_CONFIRM',
  Complete = 'COMPLETE',
  Cancelled = 'CANCELLED',
  Rejected = "REJECTED"
}
