import { Injectable } from "@angular/core";

Injectable({ providedIn: 'root' })
export const firebaseConfig = {
    apiKey: "AIzaSyASAqIns6Iafn6onAABqjtH6BUrAK8zWTQ",
    authDomain: "eservsol-a33dc.firebaseapp.com",
    projectId: "eservsol-a33dc",
    storageBucket: "eservsol-a33dc.appspot.com",
    messagingSenderId: "847945805342",
    appId: "1:847945805342:web:a9017ee45cfa6ae0bbc80f",
    measurementId: "G-W70350T392"
};