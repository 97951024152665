import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OtpInputComponent {

  otp: string[] = new Array(12).fill('');

  @Output() otpChanged = new EventEmitter<string>();

  @ViewChild('otp1', { static: false }) otp1;
  @ViewChild('otp2', { static: false }) otp2;
  @ViewChild('otp3', { static: false }) otp3;
  @ViewChild('otp4', { static: false }) otp4;
  @ViewChild('otp5', { static: false }) otp5;
  @ViewChild('otp6', { static: false }) otp6;
  @ViewChild('otp7', { static: false }) otp7;
  @ViewChild('otp8', { static: false }) otp8;
  @ViewChild('otp9', { static: false }) otp9;
  @ViewChild('otp10', { static: false }) otp10;
  @ViewChild('otp11', { static: false }) otp11;
  @ViewChild('otp12', { static: false }) otp12;




  onOtpInputChange() {

    // Join the OTP array elements into a single string and emit it
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }

  otp1Press(event: KeyboardEvent) {
    //console.log(event);

    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")
      if (event.key === 'Backspace' && this.otp1.value.length === 0) {
        this.otp1.setFocus();
      }
      else {
        this.otp2.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp2Press(event: KeyboardEvent) {
    //console.log(event);
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")
      if (event.key === 'Backspace' && this.otp2.value.length === 0) {
        this.otp1.setFocus();
      }
      else {
        this.otp3.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp3Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")
      if (event.key === 'Backspace' && this.otp3.value.length === 0) {
        this.otp2.setFocus();
      }
      else {
        this.otp4.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp4Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")
      if (event.key === 'Backspace' && this.otp4.value.length === 0) {
        this.otp3.setFocus();
      }
      else {
        this.otp5.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp5Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")
      if (event.key === 'Backspace' && this.otp5.value.length === 0) {
        this.otp4.setFocus();
      }
      else {
        this.otp6.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp6Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp6.value.length === 0) {
        this.otp5.setFocus();
      }
      else {
        this.otp7.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp7Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp7.value.length === 0) {
        this.otp6.setFocus();
      }
      else {
        this.otp8.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp8Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp8.value.length === 0) {
        this.otp7.setFocus();
      }
      else {
        this.otp9.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp9Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp9.value.length === 0) {
        this.otp8.setFocus();
      }
      else {
        this.otp10.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp10Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp10.value.length === 0) {
        this.otp9.setFocus();
      }
      else {
        this.otp11.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp11Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp11.value.length === 0) {
        this.otp10.setFocus();
      }
      else {
        this.otp12.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);
  }
  otp12Press(event: KeyboardEvent) {
    if (event.key != "Shift" && event.key != "Control" && event.key != "Meta" && event.key != "Alt")

      if (event.key === 'Backspace' && this.otp12.value.length === 0) {
        this.otp11.setFocus();
      }
    const otpValue = this.otp.join('');
    this.otpChanged.emit(otpValue);

  }
}