import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eservsol-header',
  templateUrl: './eservsol.component.html',
  styleUrls: ['./eservsol.component.scss'],
})
export class EservsolComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
