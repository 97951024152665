import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  styleUrls: ['./primary-header.component.scss'],
})
export class PrimaryHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() toolbarColor = 'primary';
  @Input() menuButtonColor = 'light';

  constructor() { }

  ngOnInit() {}

}
