import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject } from 'rxjs';
import { Company, EmployeeOrder, Order, RolesEnum, StatusEnum } from 'src/api/models';
import { CompaniesService, EmployeesService } from 'src/api/services';
import { EmployeeOrderManager } from 'src/app/models/employee-order-manager';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeFacadeService {

  employeeSelectedByManager = new BehaviorSubject<any>(null);

  constructor(
    public employeeService: EmployeesService,
    public companyService: CompaniesService,
    public permissions: NgxPermissionsService,
    public authService: AuthService
  ) { }

  async getEmployeeOrders() {
    const response = await (await this.employeeService.employeesOrdersList()).toPromise() as Order[];

    /**
     * If employee is a manager, then we duplicate their orders
     * based on number of service candidates in that order.
     */

    if (await this.permissions.hasPermission(RolesEnum.SpEmpManager)) {
      const userId = await (await this.authService.getUserInfo()).id;
      const user = (await this.authService.getUserInfo());
      const managersOrders: EmployeeOrderManager[] = [];

      for (const order of response) {


        if (!order.candidate_service_providers || order.candidate_service_providers.length === 0) {
          (order as any).candidate = null;
          managersOrders.push(Object.assign({}, order as any));
        } else {
          for (const candidate of order.candidate_service_providers) {

            if (userId === (candidate as any).id) {
              (candidate as any).loggedInUser = true;
            }

            (order as any).candidate = candidate;

            if (order.status == StatusEnum.Complete || order.status == StatusEnum.Accepted || order.status == StatusEnum.OnTheWay || order.status == StatusEnum.WorkComplete || order.status == StatusEnum.WorkStarted) {
              if ((candidate as any).id == order.service_provider.id) {

                managersOrders.push(Object.assign({}, order as any));
              }
            }
            else {

              managersOrders.push(Object.assign({}, order as any));



            }

          }
        }
      }

      return managersOrders;
    } else if (await this.permissions.hasPermission(RolesEnum.SpEmployee)) {
      const userId = await (await this.authService.getUserInfo()).id;
      const employeeOrders: EmployeeOrderManager[] = [];

      for (const order of response) {
        if (!order.candidate_service_providers || order.candidate_service_providers.length === 0) {
          (order as any).candidate = null;
          employeeOrders.push(Object.assign({}, order as any));
        } else {
          for (const candidate of order.candidate_service_providers) {

            if (userId === (candidate as any).id) {
              (candidate as any).loggedInUser = true;
              (order as any).candidate = candidate;
            }

          }

          employeeOrders.push(Object.assign({}, order as any));
        }
      }

      return employeeOrders;
    }

    return response;
  }

  async getOrder(orderId: number): Promise<EmployeeOrder> {
    return await (await this.employeeService.employeesOrdersRetrieve({
      id: orderId
    })).toPromise();
  }

  async getAdverOrder(orderId: number): Promise<EmployeeOrder> {
    return await (await this.employeeService.employeesAdverOrdersRetrieve({
      id: orderId
    })).toPromise();
  }

  async sendChatOrder(sender: string, receiver: string, messageType: string, is_offer: string, serviceProviderRequest: string, content: string, media: any): Promise<any> {
    return await (await this.employeeService.sendOrderChatRetrieve({
      sender: sender,
      receiver: receiver,
      messageType: messageType,
      is_offer: is_offer,
      serviceProviderRequest: serviceProviderRequest,
      content: content,
      media: media
    })).toPromise();


  }


  async getQuoteUrl(id: string): Promise<any> {
    return await (await this.employeeService.getQuoteUrlRetrieve({
      id: id
    })).toPromise();


  }

  async sendChatOffer(param: any): Promise<any> {
    return await (await this.employeeService.sendChatOfferRetrieve(
      param
    )).toPromise();
  }

  async getChatOffer(param: any): Promise<any> {
    return await (await this.employeeService.getChatOfferRetrieve(
      param
    )).toPromise();
  }

  async changeOfferStatus(param: any): Promise<any> {
    return await (await this.employeeService.changeOfferStatusRetrieve(
      param
    )).toPromise();
  }

  async changeOfferPaidStatus(param: any): Promise<any> {
    return await (await this.employeeService.changeOfferPaidStatusRetrieve(
      param
    )).toPromise();
  }

  async getChatOrder(orderId: string, serviceProviderId: string): Promise<any> {
    return await (await this.employeeService.getOrderChatRetrieve({
      orderId: orderId,
      serviceProviderId: serviceProviderId
    })).toPromise();
  }

  async saveItem(type: string, ServiceProvider: string, price: string, name: string, remarks: string, formated_price: string): Promise<any> {
    return await (await this.employeeService.saveItemRetrieve({
      type: type,
      ServiceProvider: ServiceProvider,
      price: price,
      name: name,
      remarks: remarks,
      formated_price: formated_price
    })).toPromise();
  }

  async updateItem(type: string, ServiceProvider: string, price: string, name: string, remarks: string, id: string, formated_price: string): Promise<EmployeeOrder> {
    return await (await this.employeeService.updateItemRetrieve({
      type: type,
      ServiceProvider: ServiceProvider,
      price: price,
      name: name,
      remarks: remarks,
      id: id,
      formated_price: formated_price
    })).toPromise();
  }

  async getItems(ServiceProvider: string, type: string): Promise<any> {
    return await (await this.employeeService.getItemsRetrieve({
      ServiceProvider: ServiceProvider,
      type: type
    })).toPromise();
  }

  async savePaymentMethod(param: any): Promise<any> {
    return await (await this.employeeService.savePaymentMethodRetrieve$Response(param)).toPromise();
  }

  async saveBankDetail(param: any): Promise<any> {
    return await (await this.employeeService.saveBankDetailRetrieve$Response(param)).toPromise();
  }

  async editBankDetail(param: any): Promise<any> {
    return await (await this.employeeService.editBankDetailRetrieve$Response(param)).toPromise();
  }

  async getBankDetail(param: any): Promise<any> {
    return await (await this.employeeService.getBankDetailRetrieve$Response(param)).toPromise();
  }

  async editPaymentMethod(id, param: any): Promise<any> {
    return await (await this.employeeService.editPaymentMethodRetrieve$Response(id, param)).toPromise();
  }

  async deletePaymentMethod(id): Promise<any> {
    return await (await this.employeeService.deletePaymentMethodRetrieve$Response(id)).toPromise();
  }

  async getPaymentMethod(): Promise<any> {
    return await (await this.employeeService.getPaymentMethodsRetrieve({

    })).toPromise();
  }

  async getCompanyFromOrder(order: Order): Promise<Company> {
    return order.location_description.company;
  }

  async chooseEmployee(employeeId: string) {
    this.employeeSelectedByManager.next(employeeId);
  }

  async resetChosenEmployee() {
    this.employeeSelectedByManager.next(null);
  }

}
