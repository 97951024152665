import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './pages/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'technicians',
    loadChildren: () => import('./pages/technicians/technicians.module').then(m => m.TechniciansPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./pages/locations/locations.module').then(m => m.LocationsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'timetracker',
    loadChildren: () => import('./pages/timetracker/timetracker/timetracker.module').then( m => m.TimetrackerPageModule)
  },
  {
    path: 'overviewweek',
    loadChildren: () => import('./pages/timetracker/overviewweek/overviewweek.module').then( m => m.OverviewweekPageModule)
  },
 
  {
    path: 'timetrackersetting',
    loadChildren: () => import('./pages/timetracker/timetrackersetting/timetrackersetting.module').then( m => m.TimetrackersettingPageModule)
  },
  {
    path: 'workinghourpopup',
    loadChildren: () => import('./pages/timetracker/workinghourpopup/workinghourpopup.module').then( m => m.WorkinghourpopupPageModule)
  },
  {
    path: 'manualtimerecord',
    loadChildren: () => import('./pages/timetracker/manualtimerecord/manualtimerecord.module').then( m => m.ManualtimerecordPageModule)
  },
  {
    path: 'exporttimesheet',
    loadChildren: () => import('./pages/timetracker/exporttimesheet/exporttimesheet.module').then( m => m.ExporttimesheetPageModule)
  },
  {
    path: 'workingdaypopup',
    loadChildren: () => import('./pages/timetracker/workingdaypopup/workingdaypopup.module').then( m => m.WorkingdaypopupPageModule)
  },
  {
    path: 'stoppopup',
    loadChildren: () => import('./pages/timetracker/stoppopup/stoppopup.module').then( m => m.StoppopupPageModule)
  },
  {
    path: 'stoponceinaday',
    loadChildren: () => import('./pages/timetracker/stoponceinaday/stoponceinaday.module').then( m => m.StoponceinadayPageModule)
  },
  {
    path: 'furtherdatevalidation',
    loadChildren: () => import('./pages/timetracker/furtherdatevalidation/furtherdatevalidation.module').then( m => m.FurtherdatevalidationPageModule)
  },
  {
    path: 'refreshtimepopup',
    loadChildren: () => import('./pages/timetracker/refreshtimepopup/refreshtimepopup.module').then( m => m.RefreshtimepopupPageModule)
  }
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
