import { Component, Input, OnInit } from '@angular/core';
import { OrderType } from '../../order-creation/order-creation.config';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-order-type-option',
  templateUrl: './order-type-option.component.html',
  styleUrls: ['./order-type-option.component.scss'],
})
export class OrderTypeOptionComponent implements OnInit {

  @Input() orderType: OrderType;

  loaded: boolean = false;

  constructor(private loadingController: LoadingController) { }

  ngOnInit() {



  }

  async loadImageWithLoader() {
    this.loaded = true;
    const loader = await this.loadingController.create({
      message: 'Loading Image...',
    });
    await loader.present();

    const img = new Image();
    img.onload = () => {
      this.loaded = false;
      loader.dismiss();
    };
    img.src = this.orderType.imageUrl;
  }

}
