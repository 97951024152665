import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-activity-item',
  templateUrl: './offer-activity-item.component.html',
  styleUrls: ['./offer-activity-item.component.scss'],
})
export class OfferActivityItemComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
