import { AreaEnum, Type2DeEnum as OrderTypeEnum } from 'src/api/models';

export interface OrderType {
    name: string;
    id: number;
    imageUrl: string;
    value: OrderTypeEnum;
    desc: string;
}

export interface OrderArea {
    name: string;
    id: number;
    imageUrl: string;
    value: AreaEnum;
}

export const OrderTypes: OrderType[] = [
    {
        name: 'emergency-repair-order',
        id: 0,
        imageUrl: '/assets/images/orders/kind/0.svg',
        value: OrderTypeEnum.EmergencyRepair,
        desc: "Create an Emergency/Rush order if you urgently need a service partner (within the next 24 hours). Billing is based on the service partner's hourly rate. In this option, you can select the appropriate contractor based on hourly wage, distance and qualification. One of these contractors can then accept your order with one click."
    },
    // {
    //     name: 'predictive-maintenance',
    //     id: 1,
    //     imageUrl: '/assets/images/orders/kind/1.svg',
    //     value: OrderTypeEnum.PredictiveMaintenance
    // },
    {
        name: 'request-a-new-project-offer',
        id: 2,
        imageUrl: '/assets/images/orders/kind/1.svg',
        value: OrderTypeEnum.NewProject,
        desc: "If you want to receive quotes from Service Partner, select this option. You will then receive the offers conveniently in the order overview. You can then accept or reject the offers or clarify any ambiguities directly with the contractor via the chat function. Here you decide whether and which offer you would like to accept."
    }
];

export const OrderAreas: OrderArea[] = [
    {
        name: 'plants-machines',
        id: 0,
        imageUrl: '/assets/images/orders/department/machines.svg',
        value: AreaEnum.Plants
    },
    {
        name: 'PKW / LKW',
        id: 1,
        imageUrl: '/assets/images/orders/department/trucks.svg',
        value: AreaEnum.CarTruck
    },
    {
        name: 'E-Mobility',
        id: 2,
        imageUrl: '/assets/images/orders/department/e-mobility.svg',
        value: AreaEnum.EMobility
    },
    {
        name: 'door-gate',
        id: 3,
        imageUrl: '/assets/images/orders/department/gates.svg',
        value: AreaEnum.Door
    },
    {
        name: 'forklift-trucks-conveyor-vehicles',
        id: 4,
        imageUrl: '/assets/images/orders/department/forklifts.svg',
        value: AreaEnum.ForkliftsConveyors
    },
    {
        name: 'house-technic',
        id: 5,
        imageUrl: '/assets/images/orders/department/house_electronics.svg',
        value: AreaEnum.BuildingServices
    },
    {
        name: 'cold-air-conditioning-sanitary',
        id: 6,
        imageUrl: '/assets/images/orders/department/heating.svg',
        value: AreaEnum.Refrigeration
    },
    {
        name: 'lifting-platforms-lift',
        id: 7,
        imageUrl: '/assets/images/orders/department/lifts.svg',
        value: AreaEnum.LiftingPlatforms
    },
    {
        name: 'window-construction-glass',
        id: 8,
        imageUrl: '/assets/images/orders/department/window.svg',
        value: AreaEnum.WindowGlass
    },
    {
        name: 'stage-event-technology',
        id: 9,
        imageUrl: '/assets/images/orders/department/stage.svg',
        value: AreaEnum.EventTech
    },
    {
        name: 'it-presentation-technology',
        id: 10,
        imageUrl: '/assets/images/orders/department/presentation.svg',
        value: AreaEnum.ItPresentationTech
    },
    {
        name: 'drywall-masonry-work',
        id: 11,
        imageUrl: '/assets/images/orders/department/wall.svg',
        value: AreaEnum.DrywallMasonry
    },
    {
        name: 'outdoor-area',
        id: 12,
        imageUrl: '/assets/images/orders/department/lawn.svg',
        value: AreaEnum.Outdoor
    },
    {
        name: 'Welding',
        id: 13,
        imageUrl: '/assets/images/orders/department/welding.svg',
        value: AreaEnum.Welding
    },
    {
        name: 'Cleaning',
        id: 14,
        imageUrl: '/assets/images/orders/department/cleaning.svg',
        value: AreaEnum.Cleaning
    },

    {
        name: 'Pool / Pond',
        id: 16,
        imageUrl: '/assets/images/orders/department/POOL.svg',
        value: AreaEnum.POOL_POND
    },
    {
        name: 'Moving / Logistics',
        id: 17,
        imageUrl: '/assets/images/orders/department/MOVING.svg',
        value: AreaEnum.MOVING_LOGISTICS
    },
    {
        name: 'Tiles / Floor ',
        id: 18,
        imageUrl: '/assets/images/orders/department/TILER.svg',
        value: AreaEnum.TILES_FLOOR
    },
    {
        name: 'Painting / Wallpapering',
        id: 19,
        imageUrl: '/assets/images/orders/department/PAINTER.svg',
        value: AreaEnum.PAINTING_WALLPAPERING
    },
    {
        name: 'Construction',
        id: 20,
        imageUrl: '/assets/images/orders/department/EXCAVATOR.svg',
        value: AreaEnum.CONSTRUCTION
    },
    {
        name: 'Roof',
        id: 21,
        imageUrl: '/assets/images/orders/department/ROOFER.svg',
        value: AreaEnum.ROOF
    },
    {
        name: 'Woodwork / Carpenter',
        id: 22,
        imageUrl: '/assets/images/orders/department/CARPENTER.svg',
        value: AreaEnum.WOODWORK_CARPENTER
    },
    {
        name: 'Interior design',
        id: 23,
        imageUrl: '/assets/images/orders/department/DECORATOR.svg',
        value: AreaEnum.INTERIOR_DESIGN
    },
    {
        name: 'General',
        id: 15,
        imageUrl: '/assets/images/orders/department/others.svg',
        value: AreaEnum.General
    },
];

export enum ServiceTimeTypeEnum {
    Days = 'days',
    Hours = 'h',
    Date = '',
}

export interface ServiceTimeOption {
    id: number;
    value: number;
    type: ServiceTimeTypeEnum;
    factor?: number;
}

export interface ServiceTime {
    orderType: OrderTypeEnum;
    values: ServiceTimeOption[];
}

export const ServiceTimeOptionMultiplicationFactor = [
    {
        type: ServiceTimeTypeEnum.Days,
        factor: 24,
        momentTimeFormat: 'MMM Do YYYY'
    },
    {
        type: ServiceTimeTypeEnum.Hours,
        factor: 1,
        momentTimeFormat: 'HH:MM[h], MMM Do YYYY'
    },
    {
        type: ServiceTimeTypeEnum.Date,
        factor: 24,
        momentTimeFormat: 'MMMM Do YYYY'
    }
];


export const ServiceTimeOptions: ServiceTime[] = [
    {
        orderType: OrderTypeEnum.EmergencyRepair,
        values: [
            {
                id: 0,
                value: 2,
                type: ServiceTimeTypeEnum.Hours,
            },
            {
                id: 1,
                value: 4,
                type: ServiceTimeTypeEnum.Hours,
            },
            {
                id: 2,
                value: 6,
                type: ServiceTimeTypeEnum.Hours,
            },
            {
                id: 3,
                value: 24,
                type: ServiceTimeTypeEnum.Hours,
            }
        ]
    },
    {
        orderType: OrderTypeEnum.PredictiveMaintenance,
        values: [
            {
                id: 0,
                value: 2,
                type: ServiceTimeTypeEnum.Days,
            },
            {
                id: 1,
                value: 3,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 2,
                value: 4,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 3,
                value: 5,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 4,
                value: 7,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 5,
                value: 10,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 6,
                value: 14,
                type: ServiceTimeTypeEnum.Days
            },
        ]
    },
    {
        orderType: OrderTypeEnum.NewProject,
        values: [
            {
                id: 0,
                value: 2,
                type: ServiceTimeTypeEnum.Days,
            },
            {
                id: 1,
                value: 3,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 2,
                value: 4,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 3,
                value: 5,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 4,
                value: 7,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 5,
                value: 10,
                type: ServiceTimeTypeEnum.Days
            },
            {
                id: 6,
                value: 14,
                type: ServiceTimeTypeEnum.Days
            },
        ]
    }

];
