import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/api/models';
import { TimeService } from 'src/app/shared/services/time.service';
import { EmployeeFacadeService } from '../../employee-facade.service';
import * as _ from 'lodash';
import { Distance } from 'src/app/shared/distance';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-order-detail-item-large',
  templateUrl: './order-detail-item-large.component.html',
  styleUrls: ['./order-detail-item-large.component.scss'],
})
export class OrderDetailItemLargeComponent implements OnInit {

  @Input() order: any;
  company: Company;
  readonly Distance = Distance;

  constructor(
    public employeeService: EmployeeFacadeService,
    public nav: NavController
  ) { }

  async ngOnInit() {
    this.company = await this.employeeService.getCompanyFromOrder(this.order);

    // //console.log("this order", this.order);



  }

  get candidate() {
    return JSON.stringify(this.order.candidate)
  }

  click() {
    // [routerLink]="order.type != 'ADVERTISEMENT' ? ['/technicians/orders/order', order.id, order.candidate] : ['/technicians/orders/order', order.id,order.type,order.candidate]"
    //console.log("sadfsdf");
    if (this.order.type != 'ADVERTISEMENT') {
      this.nav.navigateForward(['/technicians/orders/order', this.order.id, this.order.candidate]);
    }
    else {
      this.nav.navigateForward(['/technicians/orders/order', this.order.id, this.order.type, JSON.stringify(this.order.candidate)]);
    }

  }

}
